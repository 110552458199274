const handleFileDownload = async (fileKey) => {
  try {
    const url = `https://s3api.vicpos.com.au/get_s3_download_url?object_key=${fileKey}&expiration=3600`;
    const response = await fetch(url);
    const responseJson = await response.json();
    const fileUrl = responseJson.presigned_url;
    const fileName = fileKey.substring(fileKey.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
  } catch (error) {
    console.error("Error fetching file content:", error);
  }
};

export default handleFileDownload;
