import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Badge, Spinner, NavDropdown } from "react-bootstrap";
import {
  ExternalLinkIcon,
  HomeIcon,
  MapIcon,
  ClipboardListIcon,
  LoginIcon,
  LogoutIcon,
  ChartPieIcon,
  ClockIcon,
  ArchiveIcon,
  CheckCircleIcon,
} from "@heroicons/react/solid";
import { CorsAzureSydContext } from "../contexts/CorsAzureSydContext";
// import { CorsAzureMelContext } from "../contexts/CorsAzureMelContext";

import { UserContext } from "../contexts/UserContext";
import logo from "../img/vicmap_trans.png";

function Navi() {
  const { corsData } = useContext(CorsAzureSydContext);
  // const { corsData } = useContext(CorsAzureMelContext);
  const [expanded, setExpanded] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <Navbar
      sticky="top"
      bg="dark"
      variant="dark"
      expand="lg"
      expanded={expanded}>
      <Navbar.Brand>
        <img src={logo} alt="vicmap logo" className="logo"></img> GPSnet{" "}
        <Badge variant="primary">
          {corsData.length === 0 ? (
            <div>
              <Spinner
                animation="border"
                variant="light"
                style={{ height: "20px", width: "20px" }}
              />{" "}
              loading...
            </div>
          ) : (
            <div>{corsData.length} CORS</div>
          )}
        </Badge>
      </Navbar.Brand>

      <Navbar.Text>Web App v1.20.30</Navbar.Text>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(!expanded)}
      />
      <Navbar.Collapse id="justify-content-end">
        <Nav className="m-auto">
          <Nav.Link as={Link} to="/" onClick={() => setExpanded(!expanded)}>
            <HomeIcon style={{ height: "15px" }} /> Home
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/CORSMap"
            onClick={() => setExpanded(!expanded)}>
            <MapIcon style={{ height: "15px" }} /> CORS Map
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/CorsTimeSeries"
            onClick={() => setExpanded(!expanded)}>
            <ClockIcon style={{ height: "15px" }} /> CORS Time Series
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/DataRepo"
            onClick={() => setExpanded(!expanded)}>
            <ArchiveIcon style={{ height: "15px" }} /> Data Repo
          </Nav.Link>

          <NavDropdown title="CORS Availability" id="basic-nav-dropdown">
            <div
              style={{
                maxHeight: "200px",
                overflowY: "scroll",
              }}>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/jvP7iWjnz/azure-melbourne-port-2020-availability?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Mel Port 2020
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/7YLVZZjnk/azure-melbourne-port-2101-availability?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Mel Port 2101
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/C4nFWWC7k/azure-melbourne-port-8080-availability?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Mel Port 8080
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/efVDzWCnz/azure-mel-port-2105-updated-oct-2023?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Mel Port 2105
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/qpSRmZjnk/azure-sydney-port-2020-availability?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Syd Port 2020
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/KvRKiWj7k/azure-sydney-port-2101-availability?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Syd Port 2101
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/qL0-mZC7k/azure-sydney-port-8080-availability?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Syd Port 8080
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/d/5_gJgyI4Q/azure-syd-port-2105-updated-oct-2022?orgId=1&theme=light&kiosk=tv"
                target="_blank"
                className="subMenu">
                <ChartPieIcon style={{ height: "15px" }} /> Azure Syd Port 2105
                {"     "}
              </NavDropdown.Item>
            </div>
          </NavDropdown>
          <NavDropdown title="File Completeness" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/corsAzureMel" className="subMenu">
              <ChartPieIcon style={{ height: "15px" }} /> Azure Mel Data Centre
              {"     "}
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/corsAzureSyd" className="subMenu">
              <ChartPieIcon style={{ height: "15px" }} /> Azure Syd Data Centre
              {"     "}
            </NavDropdown.Item>
            <NavDropdown.Divider />

            <NavDropdown.Item
              as={Link}
              to="/corsAzureMelTest"
              className="subMenu">
              <ChartPieIcon style={{ height: "15px" }} /> Azure Mel Data Centre
              Test CORS
              {"     "}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/corsAzureSydTest"
              className="subMenu">
              <ChartPieIcon style={{ height: "15px" }} /> Azure Syd Data Centre
              Test CORS
              {"     "}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as={Link} to="/reg13Checker" className="subMenu">
              <CheckCircleIcon style={{ height: "15px" }} /> NPI Reg13 Checker
              {"     "}
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/SiteStatus" className="subMenu">
              <CheckCircleIcon style={{ height: "15px" }} /> Site Date
              {"     "}
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link
            as={Link}
            to="/Report"
            onClick={() => setExpanded(!expanded)}>
            <ClipboardListIcon style={{ height: "15px" }} />
            Report
          </Nav.Link>
          <NavDropdown title="Inf Mgr" id="basic-nav-dropdown">
            <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
              {user.username && (
                <>
                  <NavDropdown.Item
                    as={Link}
                    to="/IsrManager"
                    className="subMenu">
                    <CheckCircleIcon style={{ height: "15px" }} /> ISR Manager
                    {"     "}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/PduManager"
                    className="subMenu">
                    <CheckCircleIcon style={{ height: "15px" }} /> PDU Manager
                    {"     "}
                  </NavDropdown.Item>
                </>
              )}

              <NavDropdown.Item
                as={Link}
                to="/PubIsrManager"
                className="subMenu">
                <CheckCircleIcon style={{ height: "15px" }} /> ISR Manager (Pub)
                {"     "}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/PowerNotification"
                className="subMenu">
                <CheckCircleIcon style={{ height: "15px" }} /> UPS Manager (Pub)
                {"     "}
              </NavDropdown.Item>
            </div>
          </NavDropdown>

          <NavDropdown title="External Resource" id="basic-nav-dropdown">
            <div
              style={{
                maxHeight: "200px",
                overflowY: "scroll",
              }}>
              <NavDropdown.Item
                href="https://mon.vicpos.com.au/zabbix"
                target="_blank"
                className="subMenu">
                <ExternalLinkIcon style={{ height: "15px" }} /> Cors Monitoring
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://uptime.vicpos.com.au/"
                target="_blank"
                className="subMenu">
                <ExternalLinkIcon style={{ height: "15px" }} /> Cors Dashboard
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href="https://cms.vicpos.com.au/admin"
                target="_blank"
                className="subMenu">
                <ExternalLinkIcon style={{ height: "15px" }} />
                GPSnet CMS
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://asset.vicpos.com.au/"
                target="_blank"
                className="subMenu">
                <ExternalLinkIcon style={{ height: "15px" }} />
                GPSnet Asset
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://forms.office.com/r/Mn2MzqX1Bw"
                target="_blank"
                className="subMenu">
                <ExternalLinkIcon style={{ height: "15px" }} />
                CORS maintenance Form 2023
              </NavDropdown.Item>

              <NavDropdown.Item
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=99a96Bj8SE6lVH9UeSciO-s84lPZEllNmCZma75rijhUNkpIRlpPUVNaQVZXOEkwRkQ3Nk9HNTRVUC4u&wdLOR=c8B021FB4-5DB1-4A3B-B678-B94871B225F2"
                target="_blank"
                className="subMenu">
                <ExternalLinkIcon style={{ height: "15px" }} />
                Site Survey Form 2023
              </NavDropdown.Item>
            </div>
          </NavDropdown>
          <Nav.Link
            as={Link}
            to="/Login"
            onClick={() => setExpanded(!expanded)}>
            {user.username ? (
              <div>
                <LogoutIcon style={{ height: "15px" }} /> Logout{" "}
                <Badge variant="success">{user.username}</Badge>
              </div>
            ) : (
              <div>
                <LoginIcon style={{ height: "15px" }} />
                Login
              </div>
            )}
          </Nav.Link>
          {user.username && (
            <NavDropdown title="Test" id="basic-nav-dropdown">
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "scroll",
                }}></div>
              <NavDropdown.Item
                as={Link}
                to="/AwsTestLambda"
                className="subMenu">
                <CheckCircleIcon style={{ height: "15px" }} /> AWS Lambda Test
                {"     "}
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navi;
