import React from "react";

const handleMultipleFileDownload = async (fileKeys) => {
  try {
    for (const fileKey of fileKeys) {
      const url = `https://s3api.vicpos.com.au/get_s3_download_url?object_key=${fileKey}&expiration=3600`;
      console.log("Fetching URL:", url);
      const response = await fetch(url);
      const responseJson = await response.json();
      const fileUrl = responseJson.presigned_url;
      const fileName = fileKey.substring(fileKey.lastIndexOf("/") + 1);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
      // wait for 1 second before downloading the next file
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  } catch (error) {
    console.error(
      "Error generating pre-signed URLs or downloading files:",
      error
    );
  }
};

const MultipleFileDL = ({ fileKeys }) => {
  return (
    <div>
      <button onClick={() => handleMultipleFileDownload(fileKeys)}>
        Download Files
      </button>
    </div>
  );
};

export { MultipleFileDL, handleMultipleFileDownload };
