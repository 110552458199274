import { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ExternalLinkIcon } from "@heroicons/react/solid";

export default function FetchReg13URL({ site_code }) {
  const [isLoading, setIsLoading] = useState(true);
  const [reg13URL, setReg13URL] = useState("");

  const fetchReg13URL = async () => {
    let api = "https://s3api.vicpos.com.au/get_reg13_status_v2_site?site=";
    await axios
      .get(api + site_code)
      .then((response) => {
        // console.log(response.data);
        if (response.data === "") {
          console.log("No reg13 file found for this site");
          setReg13URL("");
        } else {
          if (response.data.length > 0) {
            let file_url =
              "https://ga-gnss-products-v1.s3.amazonaws.com/public/reg13/" +
              response.data[0].file_name;
            setReg13URL(file_url);
          } else {
            console.log("No reg13 file found for this site");
            setReg13URL("");
          }
        }
        setIsLoading(false); // Ensure loading state is updated
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false); // Ensure loading state is updated on error
      });
  };

  useEffect(() => {
    fetchReg13URL();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" />
      ) : reg13URL === "" ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: "250px" }}
            disabled>
            <ExternalLinkIcon style={{ height: "15px" }} /> {"  "}
            Reg 13 n/a
          </Button>
        </>
      ) : (
        <Link to={{ pathname: reg13URL }} target="_blank">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "250px" }}>
            <ExternalLinkIcon style={{ height: "15px" }} /> {"  "}
            Reg 13
          </Button>
        </Link>
      )}
    </>
  );
}
